$max_width: 112rem;

[data-page="works"] {
  .introContainer {
    @media (max-width: $bp_sm) {
      display: flex;
      flex-direction: column;
    }

    .cover_photo {
      width: 100%;
      height: calc(100vh - 8.7rem);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      @media (max-width: $bp_sm) {
        order: 2;
        margin-top: 9rem;

        @media (max-width: $bp_sm) {
          margin-top: 5rem;
        }
      }

      & + .arrow {
        margin-top: -110px;
        height: 110px;
        display: block;
        position: relative;
        text-align: center;
        animation: 1.5s infinite alternate jump;

        @media (max-width: $bp_sm) {
          display: none;
        }

        &::after {
          content: url("../img/arrow_white.svg");
        }
      }
    }

    .whiteWrap {
      width: 100%;
      background-color: white;
      padding-bottom: 9rem;

      @media (max-width: $bp_sm) {
        padding-bottom: 0;
      }

      @media (max-width: $bp_sm) {
        margin-bottom: -4.8rem;
      }

      .intro {
        width: 100%;
        padding: 0 3.5rem;
        max-width: 116rem;
        margin: 0 auto;

        @media (max-width: $bp_sm) {
          order: 1;
          margin-bottom: 2.2rem;
          margin-top: 2rem;
        }

        .heading {
          position: relative;
          width: 100%;

          h1 {
            width: 100%;
            padding-left: calc(33.333% + 2rem);
            margin-top: 0;
            padding-top: 8.5rem;
            margin-bottom: 4rem;

            @media (max-width: $bp_sm) {
              font-size: 1.4rem;
              padding: 0;
              margin: 0;
              font-weight: bold;
            }
          }

          .infoTrigger {
            display: none;
            font-size: 1.4rem;
            padding-right: 0.4rem;

            &::after {
              content: " +";
            }

            @media (max-width: $bp_sm) {
              display: inline-block;
              border: 1px solid;
              padding: 0.4rem 0.8rem;
              margin-top: 1.8rem;
            }
          }
        }

        .description {
          transition: max-height 0.7s;
          overflow: hidden;
          display: flex;

          @media (max-width: $bp_sm) {
            max-height: 0;
            display: block;
          }

          ul {
            padding: 0 2rem 0 0;
            margin: 0;
            list-style: none;
            width: 33.333%;

            @media (max-width: $bp_sm) {
              padding-top: 2.5rem;
              width: 100%;
              padding-right: 0;
            }

            li {
              p {
                margin-bottom: 0.9rem;

                &:last-child {
                  margin-bottom: 0rem;
                }

                br {
                  @media (max-width: $bp_sm) {
                    display: none;
                  }
                }
              }

              &:not(:last-child) {
                padding-bottom: 1rem;
                margin-bottom: 0.9rem;
                border-bottom: 1px solid black;
              }

              &.award {
                display: flex;
                align-items: center;

                img {
                  max-width: 16.5rem;
                }
                span {
                  display: block;
                }
              }

              .materialname:not(:last-child) a::after {
                content: ",";
              }
            }
          }

          .texts {
            width: 66.666%;
            padding-left: 2rem;
            display: flex;

            @media (max-width: $bp_sm) {
              width: 100%;
              padding: 0 0 1rem 0;
              display: block;
            }

            .text_col {
              padding: 0 1.5rem;
              margin: 0 0 2rem;
              flex-grow: 1;
              flex-basis: 0;

              @media (max-width: $bp_sm) {
                padding: 2rem 0 0 0;
                margin: 0;
              }

              &:first-child {
                margin-left: -1.5rem;

                @media (max-width: $bp_sm) {
                  padding: 2rem 0 0 0;
                  margin: 0;
                }
              }

              &:last-child {
                margin-right: -1.5rem;

                @media (max-width: $bp_sm) {
                  padding: 2rem 0 0 0;
                  margin: 0;
                }
              }

              p {
                margin-bottom: 0rem;
                line-height: 1.5;

                &:first-child strong,
                &:first-child b {
                  display: inline-block;
                  margin-bottom: 0.4rem;
                }
              }
            }
          }
        }
      }
    }
  }

  .work_title_block,
  .work_text_block,
  .work_image_block,
  .work_double_image_block,
  .work_video_block,
  .work_gallery_block {
    margin-top: 9rem;

    @media (max-width: $bp_sm) {
      margin-top: 5rem;
    }
  }

  .work_title_block {
    h2 {
      margin: 0;
      text-align: center;
      font-size: 3.65rem;
    }
  }

  .work_text_block {
    width: 100%;
    padding: 0 2rem;
    max-width: 116rem;
    margin: 4.5rem auto 0;

    div {
      column-gap: 3rem;

      @media (max-width: $bp_sm) {
        columns: 1 !important;
      }
    }

    p {
      margin-bottom: 0rem;
      line-height: 1.5;

      &:first-child strong,
      &:first-child b {
        display: inline-block;
        margin-bottom: 0.4rem;
      }
    }
  }

  .work_image_block,
  .work_video_block,
  .work_double_image_block {
    .full {
      width: 100%;
      position: relative;
    }

    .contained {
      width: 100%;
      max-width: $max_width;
      margin: 0 auto;
      position: relative;
    }

    .only-mobile {
      display: none;

      @media (max-width: $bp_sm) {
        display: block;
      }
    }

    .only-desktop {
      display: block;

      @media (max-width: $bp_sm) {
        display: none;
      }
    }

    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
      z-index: 70;
      transition: filter 0.2s;

      &::after {
        content: url("../img/play.svg");
      }

      &:hover {
        filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.6));
      }
    }

    .mute {
      position: absolute;
      bottom: 2rem;
      right: 2rem;
      z-index: 70;
      cursor: pointer;
      display: none;

      &::after {
        content: url("../img/muted.svg");
      }

      &[data-unmuted]::after {
        content: url("../img/unmuted.svg");
      }
    }

    [data-autoplay="autoplay"] .play {
      /* display: none; */
    }

    [data-autoplay="autoplay"] .mute {
      display: inline;
    }

    .playing .play {
      display: none;
    }

    img,
    video {
      width: 100%;
    }
  }

  .work_double_image_block {
    .double {
      font-size: 0;
      white-space: nowrap;
      overflow: hidden;

      @media (max-width: $bp_md) {
        white-space: normal;
      }

      img {
        width: calc(50% - 0.5rem);

        &:first-child {
          margin-right: 1rem;
        }

        @media (max-width: $bp_md) {
          width: 100%;

          &:first-child {
            margin-right: 0;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  .work_gallery_block {
    width: 100%;
    max-width: 112rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    .gallery_forward {
      right: 0;
      cursor: url("../img/pointer_forward.svg") 48 15, pointer;
    }

    .gallery_back {
      cursor: url("../img/pointer_back.svg") 0 15, pointer;
    }

    .gallery_back,
    .gallery_forward {
      position: absolute;
      width: 15%;
      top: 0;
      bottom: 0;
      z-index: 98;
    }

    .gallery_wrap {
      font-size: 0;
      overflow: hidden;

      .gallery_container {
        width: 10000px;
        width: intrinsic;
        width: max-content;

        .slide {
          margin-right: 3rem;
          display: inline-block;

          &:last-child {
            margin-right: 0;
          }

          img,
          video {
            max-height: calc(100vh - 6.9rem - 15rem);
            max-width: calc(112rem - 15rem);
            vertical-align: middle;

            @media (max-width: $bp_lgg) {
              max-height: calc(100vh - 6.9rem - 8rem);
              max-width: 100vw;
            }
          }
        }
      }
    }

    .gallery_data {
      font-size: 1.8rem;
      margin-top: 1.5rem;

      @media (max-width: $bp_lgg) {
        padding-left: 3.5rem;
      }
    }
  }

  .bottom {
    width: calc(100% - 7rem);
    max-width: $max_width;
    padding: 1.4rem 0;
    margin: 9rem auto;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: space-between;

    @media (max-width: $bp_xs) {
      padding: 0;
    }

    a {
      font-size: 1.2rem;

      @media (max-width: $bp_sm) {
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
      }

      &.inactive {
        opacity: 0.5;
        pointer-events: none;
        text-decoration: none;
      }
    }

    div {
      display: flex;
      justify-content: center;
      flex-direction: column;

      &:nth-child(1) {
        @media (max-width: $bp_xs) {
          a {
            font-size: 0;
            color: transparent;
            width: 2rem;
            padding: 1rem 0;
            display: inline-block;
            position: relative;
            top: -2px;

            &::before {
              content: "<";
              font-size: 2rem;
              color: black;
            }
          }
        }

        text-align: left;
      }

      &:nth-child(2),
      &:nth-child(3) {
        text-align: center;
      }

      &:nth-child(4) {
        @media (max-width: $bp_xs) {
          a {
            font-size: 0;
            color: transparent;
            width: 2rem;
            padding: 1rem 0;
            display: inline-block;
            position: relative;
            top: -2px;

            &::before {
              content: ">";
              font-size: 2rem;
              color: black;
            }
          }
        }

        text-align: right;
      }
    }
  }

  &[data-info-visible] {
    .introContainer .intro {
      .heading .infoTrigger::after {
        content: " –";
      }

      .description {
        max-height: 1000px;
      }
    }
  }

  .mosaic section:first-child {
    margin-top: 0 !important;
    background-color: white;

    @media (max-width: $bp_sm) {
      margin-top: 5rem !important;
    }
  }
}
