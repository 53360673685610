.modalgallery {
  background-color: white;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  transition: 0.5s opacity;

  &[data-modal-open] {
    opacity: 1;
    pointer-events: initial;
  }

  .gallerycontainer {
    .gallery_forward {
      right: 0;
      cursor: url("../img/pointer_forward.svg") 47 14, pointer;
    }

    .gallery_back {
      cursor: url("../img/pointer_back.svg") 0 14, pointer;
    }

    .gallery_back,
    .gallery_forward {
      position: absolute;
      width: 15%;
      top: 0;
      bottom: 0;
      z-index: 98;
    }

    [data-modal-close] {
      position: absolute;
      display: inline-block;
      top: 2rem;
      right: 3.5rem;
      height: 2.3rem;
      width: 2.3rem;
      z-index: 99;
      background-image: url("../img/close.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .gallery_wrap {
      font-size: 0;
      overflow: hidden;

      .gallery_container {
        width: 10000px;
        width: intrinsic;
        width: max-content;

        .slide {
          height: 100vh;
          width: 100vw;
          display: inline-block;

          & > div,
          video {
            height: 100%;
            width: 100%;
          }

          & > div {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }
    }
  }
}
