.carousel {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;

  .carouselSlide {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;

    opacity: 0;
    transition: opacity 0.7s 0.7s;

    &:last-child {
      transition: opacity 0.7s 0s;
    }

    &[data-active-slide],
    &:first-child {
      transition: opacity 0.7s 0s;
      opacity: 1;
    }

    .title {
      color: white;
      font-size: 7.1rem;

      @media (max-width: $bp_xs) {
        font-size: 11vw;
      }
    }

    .subtitle {
      color: white;
      font-size: 2.3rem;
      margin-top: 1.2rem;
      margin-bottom: 1rem;
    }

    .title,
    .subtitle {
      display: block;
      text-align: center;
    }
  }
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-25px);
  }
}

.carousel + .arrow {
  margin-top: -110px;
  height: 110px;
  display: block;
  position: relative;
  text-align: center;
  animation: 1.5s infinite alternate jump;

  &::after {
    content: url("../img/arrow_white.svg");
  }
}
