[data-page="about"] {
  &[data-justscrolled] {
    header {
      background-color: white;
      color: black;

      .menuTrigger span {
        background-color: #000;
      }
    }
  }

  .carousel {
    height: 100vh;
  }

  h1 {
    font-size: 4.1rem;
    margin: 0 0 2rem;

    @media (max-width: $bp_sm) {
      font-size: 3.9rem;
    }
  }

  h2 {
    font-size: 4.1rem;
    margin-top: 0;
    margin-bottom: 1.8rem;
    padding-right: 6rem;

    @media (max-width: $bp_sm) {
      font-size: 3.9rem;
      padding-right: 0;
    }
  }

  h3 {
    font-size: 1.9rem;
    display: inline-block;
    font-weight: bold;

    &.alignfix {
      padding-top: 0.2rem;
    }
  }

  p {
    font-size: 1.9rem;

    &:last-child {
      margin-bottom: 1.9rem;
    }
  }

  .aboutWrap {
    padding: 7.6rem 2rem 8.2rem;

    @media (max-width: $bp_lg) {
      padding-left: 9rem !important;
      padding-right: 9rem !important;

      .row {
        margin-left: 0;
        margin-right: 0;
        display: block;

        .col,
        .col-1,
        .col-2 {
          padding: 0;
        }
      }
    }

    @media (max-width: $bp_sm) {
      padding-left: 3.5rem !important;
      padding-right: 3.5rem !important;
      padding-top: 6rem;
      padding-bottom: 6rem;
    }

    ul {
      font-size: 1.9rem;
      margin: 0 0 4.9rem;
      padding: 0;
      list-style: none;
      display: block;

      li {
        border-bottom: 1px solid black;
        padding: 1.1rem 0;
        line-height: 1.2;

        p {
          margin-bottom: 0 !important;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    &.aboutIntro {
      background-color: #ff82b6;

      @media (max-width: $bp_sm) {
        padding-top: 6rem;
        padding-bottom: 6rem;
      }

      .col:nth-child(3) {
        @media (max-width: $bp_sm) {
          margin-top: 4rem;
        }
      }

      ul {
        margin: 0 0 1.9rem -20px;

        li {
          padding: 0 0 0 20px;
          line-height: 1.4;
          position: relative;

          &::before {
            content: url("../img/li-black.svg");
            display: inline-block;
            margin-right: 10px;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }

    &.aboutServices {
      background-color: #ffa700;
    }

    &.aboutClients {
      background-color: #ffdb00;
    }

    &.aboutRecognitions {
      background-color: #48d37a;

      .recognition {
        font-size: 1.9rem;
        line-height: 1.3;

        p {
          line-height: 1.3;
          margin-bottom: 0 !important;
        }
      }

      .recognitionBlock {
        margin-bottom: 5rem;

        @media (max-width: $bp_lg) {
          margin-top: 2rem;
        }

        h3 {
          margin-bottom: 4rem;
        }

        hr {
          border-style: none;
          border-top: 1px solid black;
          margin: 1.2rem 0;

          &:last-child {
            display: none;
          }
        }

        ul {
          margin-bottom: 0;
        }
      }
    }

    &.aboutSocial {
      background-color: #1ca2ea;

      ul {
        margin: 0 0 1.9rem -20px;

        li {
          padding: 0 0 0 20px;
          line-height: 1.4;
          position: relative;

          &::before {
            content: url("../img/li-black.svg");
            display: inline-block;
            margin-right: 10px;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }

    &.aboutTeaching {
      padding-bottom: 15.3rem;
      margin-bottom: -7.4rem;
      background-color: #b4bfc3;

      .teachingList {
        .singleTeaching {
          margin-top: 9rem;

          .singleTeachingText {
            margin-top: 4rem;

            h3 {
              margin-top: 0.3rem;
              text-transform: uppercase;
            }

            .aux_texts {
              padding-right: 5rem;
              margin-bottom: 0;
            }

            p {
              margin-bottom: 0;
            }

            a[data-modal-trigger] {
              font-size: 1.9rem;
              font-weight: bold;
              margin-top: 2.2rem;

              &::before {
                content: url("../img/li-black.svg");
                display: inline-block;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }

  .columns-2 {
    column-gap: 6rem;
    margin-top: -3rem;

    h3 {
      margin-top: 4.9rem;
    }

    ul {
      margin-bottom: 0;
    }

    @media (max-width: $bp_sm) {
      columns: 1;
    }

    & > div.no-break {
      break-inside: avoid-column;
    }
  }

  .container .row .col-2 {
    @media (max-width: $bp_lg) {
      max-width: none;
      margin-top: 2rem;
    }
  }
}
