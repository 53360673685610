[data-page="home"] {
  &[data-justscrolled] {
    header {
      background-color: white;
      color: black;

      .menuTrigger span {
        background-color: #000;
      }
    }
  }

  h1 {
    padding: 12.2rem 3rem 7.5rem;
    text-align: center;
    font-size: 1.8rem;
    display: block;
    margin: -6.7rem 0 0 0;

    span {
      font-size: 3.7rem;
      position: relative;
      z-index: 100;

      @media (max-width: $bp_xs) {
        position: static;
      }
    }

    a {
      font-size: 1.3rem;
      display: none;
    }

    br {
      display: none;
    }

    &.filtered {
      a,
      br {
        display: inline;
      }
    }
  }

  .homeColumns {
    padding: 0 1.5rem 5rem;
    display: flex;
    width: 100%;
    min-height: calc(100vh - 31.2rem);

    @media (max-width: $bp_md) {
      padding-left: 0;
      padding-right: 0;
    }

    &.masonry {
      display: block;
      column-count: 3;
      column-gap: 0;

      @media (max-width: $bp_md) {
        column-count: auto;
      }

      .brick {
        padding: 0 1.5rem;
        break-inside: avoid;

        @media (max-width: $bp_md) {
          width: 100%;
          padding: 0;
          max-width: 60rem;
          margin: 0 auto;
        }
      }
    }

    @media (max-width: $bp_md) {
      display: block;
    }

    .col {
      padding: 0 1.5rem;
      width: 33.3332%;

      @media (max-width: $bp_md) {
        width: 100%;
        padding: 0;
        max-width: 60rem;
        margin: 0 auto;
      }
    }
    .project {
      opacity: 0;
      transform: translateY(30px);
      transition: transform 0.7s, opacity 0.7s;

      .imgwrap a,
      .projectTitle a {
        text-decoration: none;
        display: block;

        &:hover {
          transform: none;
        }
      }

      &[data-visible] {
        transform: translateY(0);
        opacity: 1;

        &:hover {
          .description {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }

      img {
        width: 100%;
      }

      .description {
        text-align: center;
        padding-top: 1.2rem;
        padding-bottom: 2.5rem;
        opacity: 0;
        transition: transform 0.3s, opacity 0.3s;

        @media (max-width: $bp_md) {
          opacity: 1;
          padding-bottom: 3rem;
          display: flex;
        }

        .projectTitle {
          font-size: 1.4rem;
          display: block;
          margin-bottom: 0.3rem;

          @media (max-width: $bp_md) {
            text-align: left;
            padding-left: 3.5rem;
            padding-right: 2rem;
            flex-grow: 1;
            font-size: 14px;
          }

          a {
            display: block;
            padding-top: 0.8rem;
          }
        }

        ul.taglist {
          display: block;
          margin: 0.4rem 0 0 0;
          padding: 0;
          list-style: none;
          font-size: 1rem;
          color: #999;

          @media (max-width: $bp_md) {
            display: none;
          }

          li {
            display: inline-block;
            margin: 0 0.2rem;
          }
        }

        ul.materiallist {
          display: block;
          margin: 1rem 0 0 0;
          padding: 0;
          list-style: none;
          font-size: 1rem;
          color: black;

          @media (max-width: $bp_md) {
            padding-left: 2rem;
            padding-right: 3.5rem;
            margin-top: 0;
            display: flex;
            align-items: flex-end;
            margin-bottom: 0.4rem;
            font-size: 12px;
            text-align: right;
          }

          li {
            display: inline-block;
            margin: 0 0.2rem;
            padding: 0.2rem 0.3rem;
            border: 1px solid;

            @media (max-width: $bp_md) {
              border: none;
              margin: 0 0 0 0.5rem;
              padding: 0;
              color: gray;

              &:not(:last-child) a:after {
                content: ",";
              }
            }

            &:hover {
              background-color: black;

              @media (max-width: $bp_md) {
                background-color: transparent;
              }

              a,
              a:hover {
                text-decoration: none;
                color: white;

                @media (max-width: $bp_md) {
                  color: black;
                }
              }
            }
          }
        }
      }
    }

    &.filtered {
      display: block;

      .col {
        max-width: 60rem;
        width: 100%;
        margin: 0 auto;

        .project {
          break-inside: avoid;
        }
      }
    }
  }

  &[data-workheader] {
    h1 {
      margin-bottom: 1.8rem;

      span {
        position: fixed;
        top: 2.3rem;
        font-size: 1.6rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  #works span {
    opacity: 1;
    transition: opacity 0.5s;
  }

  &[data-nav-open] #works span {
    opacity: 0;
  }

  .hideMobile {
    display: block;

    @media (max-width: $bp_md) {
      display: none;
    }
  }

  .showMobile {
    display: none;
    max-width: 60rem;
    margin: 0 auto;

    @media (max-width: $bp_md) {
      display: block;
    }
  }
}
