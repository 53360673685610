@charset "UTF-8";
html,
body {
  font-size: 10px;
  cursor: url("../img/pointer_normal.svg") 7 7, auto; }
  @media (max-width: 459px) {
    html,
    body {
      font-size: 9.5px; } }

html[data-responsivefont-override],
html[data-responsivefont-override] body {
  font-size: 10px; }
  @media (max-width: 459px) {
    html[data-responsivefont-override],
    html[data-responsivefont-override] body {
      font-size: 10px; } }

body {
  font-family: "CadizWeb";
  font-weight: 400;
  font-style: normal;
  text-rendering: optimizeLegibility;
  /* emphasizes in legibility over rendering speed */
  -webkit-font-smoothing: antialiased;
  /* apply font anti-aliasing */
  -moz-osx-font-smoothing: grayscale;
  /* optimize font rendering */
  -moz-font-feature-settings: "kern" 1;
  /* turn on kerning, highly recomened */
  -ms-font-feature-settings: "kern" 1;
  /* turn on kerning, highly recomened */
  -o-font-feature-settings: "kern" 1;
  /* turn on kerning, highly recomened */
  -webkit-font-feature-settings: "kern" 1;
  /* turn on kerning, highly recomened */
  font-feature-settings: "kern" 1;
  /* turn on kerning, highly recomened */
  font-kerning: normal;
  /* turn on kerning, highly recomened */
  font-feature-settings: "liga" on;
  /* ligatures: on or off */
  font-feature-settings: "dlig" off;
  /* discretionary-ligatures: on or off */
  font-feature-settings: "tnum" off;
  /* tabular figures: on or off */
  font-feature-settings: "onum" off;
  /* old-style-figures: on or off */
  font-feature-settings: "ss01" off;
  /* alternate glyphs (stylistic Set): on or off */
  scroll-behavior: smooth; }

a,
a:link,
a:focus,
a:hover,
a:visited {
  color: inherit;
  transition: transform 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: url("../img/pointer_pointer.svg") 7 7, pointer; }

a:hover {
  text-decoration: underline;
  /*  transform: translateY(-2px); */ }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal; }

h1 {
  font-size: 3.7rem;
  display: block; }

p {
  font-size: 1.4rem;
  margin: 0 0 1.9rem 0;
  line-height: 1.4; }
  p:last-child {
    margin-bottom: 0; }

[data-copy] textarea {
  opacity: 0;
  height: 0;
  width: 0;
  font-size: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  pointer-events: none; }

.container {
  max-width: 1130px;
  margin: 0 auto;
  width: 100%; }
  .container .row {
    margin-left: -3rem;
    margin-right: -3rem;
    display: flex; }
    .container .row .col,
    .container .row .col-1,
    .container .row .col-2 {
      padding-left: 3rem;
      padding-right: 3rem;
      flex-grow: 1;
      flex-basis: 0; }
    .container .row .col-2 {
      flex: 0 0 66.666%;
      max-width: 66.666%; }

.columns-2 {
  columns: 2; }

.nowrap {
  white-space: nowrap; }

.grecaptcha-badge {
  display: none !important; }

.text-help {
  display: none; }

.form-error-message {
  color: red;
  display: none; }

.form-success-message {
  display: none; }

.form-submit-button[data-sent] {
  display: none !important; }

.form-error-message[data-sent],
.form-success-message[data-sent] {
  display: block; }

body {
  padding-top: 6.9rem; }

header {
  transform: translateZ(0);
  height: 6.9rem;
  padding: 0 3.5rem 0.2rem 3.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  transition: color 0.5s, background-color 0.5s, top 0.5s;
  background-color: white; }
  header .logo {
    transform: translateZ(0);
    font-size: 1.6rem; }
    header .logo:link, header .logo:active, header .logo:visited, header .logo:hover, header .logo:focus {
      text-decoration: none;
      color: inherit; }
  header .menuTrigger {
    transform: translateZ(0);
    width: 3rem;
    line-height: 0;
    font-size: 0; }
    header .menuTrigger span {
      transform: translateZ(0);
      display: block;
      background-color: black;
      width: 3rem;
      height: 1px;
      margin-left: auto;
      margin-right: auto;
      transition: background-color 0.5s, transform 0.3s, opacity 0.1s; }
      header .menuTrigger span:first-child {
        margin-bottom: 8px; }
      header .menuTrigger span:last-child {
        margin-top: 8px; }
      header .menuTrigger span:nth-child(3) {
        margin-top: -1px; }

@media (max-width: 459px) {
  body[data-scrolled][data-going="down"] header {
    top: -8.7rem; } }

body[data-page="home"],
body[data-page="about"] {
  padding-top: 0; }
  body[data-page="home"] header,
  body[data-page="about"] header {
    color: white;
    background-color: transparent; }
    body[data-page="home"] header .menuTrigger span,
    body[data-page="about"] header .menuTrigger span {
      background-color: white; }
  body[data-page="home"][data-scrolled] header,
  body[data-page="about"][data-scrolled] header {
    color: black;
    background-color: white; }
    body[data-page="home"][data-scrolled] header .menuTrigger span,
    body[data-page="about"][data-scrolled] header .menuTrigger span {
      background-color: black; }

body[data-page="about"] header {
  color: black; }
  body[data-page="about"] header .menuTrigger span {
    background-color: black; }

footer {
  font-size: 1.2rem;
  padding: 3rem 3.5rem;
  display: flex;
  justify-content: space-between; }
  footer > div:nth-child(2) {
    text-align: center;
    flex-grow: 1; }
    @media (max-width: 719px) {
      footer > div:nth-child(2) {
        text-align: right; } }
  footer > div:nth-child(3) {
    text-align: right; }
  footer .socials {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0; }
    @media (max-width: 719px) {
      footer .socials {
        display: none; }
        footer .socials.privacy {
          display: inline-block; } }
    footer .socials li {
      display: inline-block;
      margin: 0 0.7rem; }
  @media (max-width: 719px) {
    footer .no-mobile {
      display: none; } }
  footer .copyright span {
    position: relative;
    top: 0.1rem; }

@keyframes rainbow {
  0% {
    background-color: #ff82b6; }
  20% {
    background-color: #3ecf7a; }
  40% {
    background-color: #1ca2ea; }
  60% {
    background-color: #ffdb00; }
  80% {
    background-color: #b4bfc3; }
  100% {
    background-color: #ff82b6; } }

@keyframes rainbowtext {
  0% {
    color: #ff82b6; }
  20% {
    color: #3ecf7a; }
  40% {
    color: #1ca2ea; }
  60% {
    color: #ffdb00; }
  80% {
    color: #b4bfc3; }
  100% {
    color: #ff82b6; } }

nav {
  opacity: 0;
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: 25s infinite rainbow;
  z-index: 89;
  display: flex;
  transition: opacity 0.5s; }
  nav .pages {
    margin: 7rem 0 0 0;
    padding: 0;
    display: block;
    list-style: none;
    height: 37rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
    @media (max-width: 719px) and (orientation: portrait) {
      nav .pages {
        justify-content: flex-start;
        height: auto; } }
    @media (max-height: 459px) and (orientation: landscape) {
      nav .pages {
        justify-content: center;
        padding-bottom: 5rem; } }
    nav .pages li {
      font-size: 8rem;
      transition: opacity 0.6s;
      opacity: 0; }
      @media (max-width: 1160px) {
        nav .pages li {
          font-size: 7.5rem; } }
      @media (max-width: 1023px) {
        nav .pages li {
          font-size: 7.5rem; } }
      @media (max-width: 719px) and (orientation: portrait) {
        nav .pages li {
          font-size: 3.7rem;
          margin-bottom: 0.5rem; } }
      @media (max-height: 459px) and (orientation: landscape) {
        nav .pages li {
          font-size: 4rem; } }
      nav .pages li a {
        text-decoration: none; }
  nav .socials {
    margin: 7.5rem 0 0 0;
    padding: 0;
    display: block;
    list-style: none;
    height: 2.1rem;
    opacity: 0;
    transition: opacity 0.6s; }
    @media (max-width: 719px) and (orientation: portrait) {
      nav .socials {
        width: calc(100vw - 5rem); } }
    @media (max-height: 459px) and (orientation: landscape) {
      nav .socials {
        display: none; } }
    nav .socials li {
      font-size: 1.8rem;
      display: inline-block;
      margin: 0 3.8rem 0 0; }
      @media (max-width: 719px) and (orientation: portrait) {
        nav .socials li {
          font-size: 1.5rem;
          margin-right: 1.5rem; } }
  nav .sub {
    margin: 0 0 9.6rem 0;
    padding: 0 0 1rem 0;
    display: block;
    list-style: none;
    height: 37rem;
    opacity: 0;
    transition: opacity 0.6s; }
    @media (max-width: 719px) and (orientation: portrait) {
      nav .sub {
        justify-content: flex-start;
        padding-top: 9.6rem;
        margin-bottom: 1rem;
        height: auto; } }
    nav .sub li {
      font-size: 1.8rem;
      display: block;
      padding-top: 1.6rem;
      margin: 0 3.8rem 0 0; }
      @media (max-width: 719px) and (orientation: portrait) {
        nav .sub li {
          font-size: 1.5rem;
          padding-top: 1.4rem; } }
      nav .sub li a {
        text-decoration: none; }
  nav .main {
    margin-left: 33.333%;
    width: 33.333%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 6rem; }
    nav .main .lang {
      height: 7rem;
      opacity: 0;
      transition: opacity 0.6s;
      animation: 25s infinite rainbowtext;
      display: none; }
      @media (max-width: 719px) and (orientation: portrait) {
        nav .main .lang {
          display: inline-block;
          margin-top: 1rem; } }
      nav .main .lang a {
        font-size: 1.6rem;
        height: 3.4rem;
        width: 3.4rem;
        border: 1px solid #000;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: black;
        text-decoration: none; }
        nav .main .lang a:hover {
          background-color: black;
          color: inherit; }
    @media (max-width: 1023px) {
      nav .main {
        margin-left: 20%;
        width: 53.333%; } }
    @media (max-width: 719px) and (orientation: portrait) {
      nav .main {
        margin-left: 3.5rem;
        width: 50%;
        padding-top: 3rem;
        justify-content: space-between; } }
    @media (max-height: 459px) and (orientation: landscape) {
      nav .main {
        width: 100%;
        margin: 0;
        text-align: center;
        padding: 0; } }
  nav .side {
    width: 33.333%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 6rem; }
    @media (max-width: 719px) and (orientation: portrait) {
      nav .side {
        justify-content: flex-start;
        width: 50%; } }
    @media (max-height: 459px) and (orientation: landscape) {
      nav .side {
        display: none; } }
    nav .side .lang {
      height: 7rem;
      opacity: 0;
      transition: opacity 0.6s;
      animation: 25s infinite rainbowtext; }
      @media (max-width: 719px) and (orientation: portrait) {
        nav .side .lang {
          display: none; } }
      nav .side .lang a {
        font-size: 1.6rem;
        height: 3.4rem;
        width: 3.4rem;
        border: 1px solid #000;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: black;
        text-decoration: none; }
        nav .side .lang a:hover {
          background-color: black;
          color: inherit; }
  nav .sub {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
    nav .sub a:not(.langlink)::after {
      transition: right 0.3s; }
  nav a:not(.langlink) {
    display: inline-block;
    position: relative;
    text-decoration: none; }
    nav a:not(.langlink)::after {
      content: "";
      display: inline;
      position: absolute;
      left: 0;
      bottom: 0.03em;
      right: 500px;
      height: 0.06em;
      background-color: black;
      transition: right 0.4s; }
    nav a:not(.langlink):hover {
      transform: none; }
      nav a:not(.langlink):hover::after {
        right: 0; }

body[data-nav-open] {
  overflow: hidden; }
  body[data-nav-open] header {
    color: black !important;
    background-color: transparent !important; }
    body[data-nav-open] header .menuTrigger span {
      background-color: black; }
      body[data-nav-open] header .menuTrigger span:first-child, body[data-nav-open] header .menuTrigger span:last-child {
        opacity: 0; }
      body[data-nav-open] header .menuTrigger span:nth-child(2) {
        transform: rotate(45deg); }
      body[data-nav-open] header .menuTrigger span:nth-child(3) {
        transform: rotate(-45deg); }
  body[data-nav-open] nav {
    opacity: 1;
    pointer-events: initial; }
    body[data-nav-open] nav .pages li {
      opacity: 1; }
      body[data-nav-open] nav .pages li:nth-child(1) {
        transition-delay: 0.2s; }
      body[data-nav-open] nav .pages li:nth-child(2) {
        transition-delay: 0.4s; }
      body[data-nav-open] nav .pages li:nth-child(3) {
        transition-delay: 0.6s; }
      body[data-nav-open] nav .pages li:nth-child(4) {
        transition-delay: 0.8s; }
    body[data-nav-open] nav .socials,
    body[data-nav-open] nav .lang,
    body[data-nav-open] nav .sub {
      opacity: 1;
      transition-delay: 1.2s; }

@keyframes pulse {
  from {
    opacity: 1; }
  to {
    opacity: 0.3; } }

.loadingSplash {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: white;
  z-index: 98;
  transition: 0.5s opacity;
  pointer-events: none; }
  .loadingSplash::after {
    content: "";
    display: inline-block;
    width: 2.2rem;
    height: 2.2rem;
    background-color: black;
    border-radius: 50%;
    animation-duration: 0.8s;
    animation-name: pulse;
    animation-iteration-count: infinite;
    animation-direction: alternate; }

[data-loading] .loadingSplash {
  opacity: 1; }

.carousel {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative; }
  .carousel .carouselSlide {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.7s 0.7s; }
    .carousel .carouselSlide:last-child {
      transition: opacity 0.7s 0s; }
    .carousel .carouselSlide[data-active-slide], .carousel .carouselSlide:first-child {
      transition: opacity 0.7s 0s;
      opacity: 1; }
    .carousel .carouselSlide .title {
      color: white;
      font-size: 7.1rem; }
      @media (max-width: 459px) {
        .carousel .carouselSlide .title {
          font-size: 11vw; } }
    .carousel .carouselSlide .subtitle {
      color: white;
      font-size: 2.3rem;
      margin-top: 1.2rem;
      margin-bottom: 1rem; }
    .carousel .carouselSlide .title,
    .carousel .carouselSlide .subtitle {
      display: block;
      text-align: center; }

@keyframes jump {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(0); }
  100% {
    transform: translateY(-25px); } }

.carousel + .arrow {
  margin-top: -110px;
  height: 110px;
  display: block;
  position: relative;
  text-align: center;
  animation: 1.5s infinite alternate jump; }
  .carousel + .arrow::after {
    content: url("../img/arrow_white.svg"); }

[data-page="home"][data-justscrolled] header {
  background-color: white;
  color: black; }
  [data-page="home"][data-justscrolled] header .menuTrigger span {
    background-color: #000; }

[data-page="home"] h1 {
  padding: 12.2rem 3rem 7.5rem;
  text-align: center;
  font-size: 1.8rem;
  display: block;
  margin: -6.7rem 0 0 0; }
  [data-page="home"] h1 span {
    font-size: 3.7rem;
    position: relative;
    z-index: 100; }
    @media (max-width: 459px) {
      [data-page="home"] h1 span {
        position: static; } }
  [data-page="home"] h1 a {
    font-size: 1.3rem;
    display: none; }
  [data-page="home"] h1 br {
    display: none; }
  [data-page="home"] h1.filtered a,
  [data-page="home"] h1.filtered br {
    display: inline; }

[data-page="home"] .homeColumns {
  padding: 0 1.5rem 5rem;
  display: flex;
  width: 100%;
  min-height: calc(100vh - 31.2rem); }
  @media (max-width: 839px) {
    [data-page="home"] .homeColumns {
      padding-left: 0;
      padding-right: 0; } }
  [data-page="home"] .homeColumns.masonry {
    display: block;
    column-count: 3;
    column-gap: 0; }
    @media (max-width: 839px) {
      [data-page="home"] .homeColumns.masonry {
        column-count: auto; } }
    [data-page="home"] .homeColumns.masonry .brick {
      padding: 0 1.5rem;
      break-inside: avoid; }
      @media (max-width: 839px) {
        [data-page="home"] .homeColumns.masonry .brick {
          width: 100%;
          padding: 0;
          max-width: 60rem;
          margin: 0 auto; } }
  @media (max-width: 839px) {
    [data-page="home"] .homeColumns {
      display: block; } }
  [data-page="home"] .homeColumns .col {
    padding: 0 1.5rem;
    width: 33.3332%; }
    @media (max-width: 839px) {
      [data-page="home"] .homeColumns .col {
        width: 100%;
        padding: 0;
        max-width: 60rem;
        margin: 0 auto; } }
  [data-page="home"] .homeColumns .project {
    opacity: 0;
    transform: translateY(30px);
    transition: transform 0.7s, opacity 0.7s; }
    [data-page="home"] .homeColumns .project .imgwrap a,
    [data-page="home"] .homeColumns .project .projectTitle a {
      text-decoration: none;
      display: block; }
      [data-page="home"] .homeColumns .project .imgwrap a:hover,
      [data-page="home"] .homeColumns .project .projectTitle a:hover {
        transform: none; }
    [data-page="home"] .homeColumns .project[data-visible] {
      transform: translateY(0);
      opacity: 1; }
      [data-page="home"] .homeColumns .project[data-visible]:hover .description {
        opacity: 1;
        transform: translateY(0); }
    [data-page="home"] .homeColumns .project img {
      width: 100%; }
    [data-page="home"] .homeColumns .project .description {
      text-align: center;
      padding-top: 1.2rem;
      padding-bottom: 2.5rem;
      opacity: 0;
      transition: transform 0.3s, opacity 0.3s; }
      @media (max-width: 839px) {
        [data-page="home"] .homeColumns .project .description {
          opacity: 1;
          padding-bottom: 3rem;
          display: flex; } }
      [data-page="home"] .homeColumns .project .description .projectTitle {
        font-size: 1.4rem;
        display: block;
        margin-bottom: 0.3rem; }
        @media (max-width: 839px) {
          [data-page="home"] .homeColumns .project .description .projectTitle {
            text-align: left;
            padding-left: 3.5rem;
            padding-right: 2rem;
            flex-grow: 1;
            font-size: 14px; } }
        [data-page="home"] .homeColumns .project .description .projectTitle a {
          display: block;
          padding-top: 0.8rem; }
      [data-page="home"] .homeColumns .project .description ul.taglist {
        display: block;
        margin: 0.4rem 0 0 0;
        padding: 0;
        list-style: none;
        font-size: 1rem;
        color: #999; }
        @media (max-width: 839px) {
          [data-page="home"] .homeColumns .project .description ul.taglist {
            display: none; } }
        [data-page="home"] .homeColumns .project .description ul.taglist li {
          display: inline-block;
          margin: 0 0.2rem; }
      [data-page="home"] .homeColumns .project .description ul.materiallist {
        display: block;
        margin: 1rem 0 0 0;
        padding: 0;
        list-style: none;
        font-size: 1rem;
        color: black; }
        @media (max-width: 839px) {
          [data-page="home"] .homeColumns .project .description ul.materiallist {
            padding-left: 2rem;
            padding-right: 3.5rem;
            margin-top: 0;
            display: flex;
            align-items: flex-end;
            margin-bottom: 0.4rem;
            font-size: 12px;
            text-align: right; } }
        [data-page="home"] .homeColumns .project .description ul.materiallist li {
          display: inline-block;
          margin: 0 0.2rem;
          padding: 0.2rem 0.3rem;
          border: 1px solid; }
          @media (max-width: 839px) {
            [data-page="home"] .homeColumns .project .description ul.materiallist li {
              border: none;
              margin: 0 0 0 0.5rem;
              padding: 0;
              color: gray; }
              [data-page="home"] .homeColumns .project .description ul.materiallist li:not(:last-child) a:after {
                content: ","; } }
          [data-page="home"] .homeColumns .project .description ul.materiallist li:hover {
            background-color: black; }
            @media (max-width: 839px) {
              [data-page="home"] .homeColumns .project .description ul.materiallist li:hover {
                background-color: transparent; } }
            [data-page="home"] .homeColumns .project .description ul.materiallist li:hover a,
            [data-page="home"] .homeColumns .project .description ul.materiallist li:hover a:hover {
              text-decoration: none;
              color: white; }
              @media (max-width: 839px) {
                [data-page="home"] .homeColumns .project .description ul.materiallist li:hover a,
                [data-page="home"] .homeColumns .project .description ul.materiallist li:hover a:hover {
                  color: black; } }
  [data-page="home"] .homeColumns.filtered {
    display: block; }
    [data-page="home"] .homeColumns.filtered .col {
      max-width: 60rem;
      width: 100%;
      margin: 0 auto; }
      [data-page="home"] .homeColumns.filtered .col .project {
        break-inside: avoid; }

[data-page="home"][data-workheader] h1 {
  margin-bottom: 1.8rem; }
  [data-page="home"][data-workheader] h1 span {
    position: fixed;
    top: 2.3rem;
    font-size: 1.6rem;
    left: 50%;
    transform: translateX(-50%); }

[data-page="home"] #works span {
  opacity: 1;
  transition: opacity 0.5s; }

[data-page="home"][data-nav-open] #works span {
  opacity: 0; }

[data-page="home"] .hideMobile {
  display: block; }
  @media (max-width: 839px) {
    [data-page="home"] .hideMobile {
      display: none; } }

[data-page="home"] .showMobile {
  display: none;
  max-width: 60rem;
  margin: 0 auto; }
  @media (max-width: 839px) {
    [data-page="home"] .showMobile {
      display: block; } }

@media (max-width: 719px) {
  [data-page="works"] .introContainer {
    display: flex;
    flex-direction: column; } }

[data-page="works"] .introContainer .cover_photo {
  width: 100%;
  height: calc(100vh - 8.7rem);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media (max-width: 719px) {
    [data-page="works"] .introContainer .cover_photo {
      order: 2;
      margin-top: 9rem; } }
  @media (max-width: 719px) and (max-width: 719px) {
    [data-page="works"] .introContainer .cover_photo {
      margin-top: 5rem; } }
  [data-page="works"] .introContainer .cover_photo + .arrow {
    margin-top: -110px;
    height: 110px;
    display: block;
    position: relative;
    text-align: center;
    animation: 1.5s infinite alternate jump; }
    @media (max-width: 719px) {
      [data-page="works"] .introContainer .cover_photo + .arrow {
        display: none; } }
    [data-page="works"] .introContainer .cover_photo + .arrow::after {
      content: url("../img/arrow_white.svg"); }

[data-page="works"] .introContainer .whiteWrap {
  width: 100%;
  background-color: white;
  padding-bottom: 9rem; }
  @media (max-width: 719px) {
    [data-page="works"] .introContainer .whiteWrap {
      padding-bottom: 0; } }
  @media (max-width: 719px) {
    [data-page="works"] .introContainer .whiteWrap {
      margin-bottom: -4.8rem; } }
  [data-page="works"] .introContainer .whiteWrap .intro {
    width: 100%;
    padding: 0 3.5rem;
    max-width: 116rem;
    margin: 0 auto; }
    @media (max-width: 719px) {
      [data-page="works"] .introContainer .whiteWrap .intro {
        order: 1;
        margin-bottom: 2.2rem;
        margin-top: 2rem; } }
    [data-page="works"] .introContainer .whiteWrap .intro .heading {
      position: relative;
      width: 100%; }
      [data-page="works"] .introContainer .whiteWrap .intro .heading h1 {
        width: 100%;
        padding-left: calc(33.333% + 2rem);
        margin-top: 0;
        padding-top: 8.5rem;
        margin-bottom: 4rem; }
        @media (max-width: 719px) {
          [data-page="works"] .introContainer .whiteWrap .intro .heading h1 {
            font-size: 1.4rem;
            padding: 0;
            margin: 0;
            font-weight: bold; } }
      [data-page="works"] .introContainer .whiteWrap .intro .heading .infoTrigger {
        display: none;
        font-size: 1.4rem;
        padding-right: 0.4rem; }
        [data-page="works"] .introContainer .whiteWrap .intro .heading .infoTrigger::after {
          content: " +"; }
        @media (max-width: 719px) {
          [data-page="works"] .introContainer .whiteWrap .intro .heading .infoTrigger {
            display: inline-block;
            border: 1px solid;
            padding: 0.4rem 0.8rem;
            margin-top: 1.8rem; } }
    [data-page="works"] .introContainer .whiteWrap .intro .description {
      transition: max-height 0.7s;
      overflow: hidden;
      display: flex; }
      @media (max-width: 719px) {
        [data-page="works"] .introContainer .whiteWrap .intro .description {
          max-height: 0;
          display: block; } }
      [data-page="works"] .introContainer .whiteWrap .intro .description ul {
        padding: 0 2rem 0 0;
        margin: 0;
        list-style: none;
        width: 33.333%; }
        @media (max-width: 719px) {
          [data-page="works"] .introContainer .whiteWrap .intro .description ul {
            padding-top: 2.5rem;
            width: 100%;
            padding-right: 0; } }
        [data-page="works"] .introContainer .whiteWrap .intro .description ul li p {
          margin-bottom: 0.9rem; }
          [data-page="works"] .introContainer .whiteWrap .intro .description ul li p:last-child {
            margin-bottom: 0rem; }
          @media (max-width: 719px) {
            [data-page="works"] .introContainer .whiteWrap .intro .description ul li p br {
              display: none; } }
        [data-page="works"] .introContainer .whiteWrap .intro .description ul li:not(:last-child) {
          padding-bottom: 1rem;
          margin-bottom: 0.9rem;
          border-bottom: 1px solid black; }
        [data-page="works"] .introContainer .whiteWrap .intro .description ul li.award {
          display: flex;
          align-items: center; }
          [data-page="works"] .introContainer .whiteWrap .intro .description ul li.award img {
            max-width: 16.5rem; }
          [data-page="works"] .introContainer .whiteWrap .intro .description ul li.award span {
            display: block; }
        [data-page="works"] .introContainer .whiteWrap .intro .description ul li .materialname:not(:last-child) a::after {
          content: ","; }
      [data-page="works"] .introContainer .whiteWrap .intro .description .texts {
        width: 66.666%;
        padding-left: 2rem;
        display: flex; }
        @media (max-width: 719px) {
          [data-page="works"] .introContainer .whiteWrap .intro .description .texts {
            width: 100%;
            padding: 0 0 1rem 0;
            display: block; } }
        [data-page="works"] .introContainer .whiteWrap .intro .description .texts .text_col {
          padding: 0 1.5rem;
          margin: 0 0 2rem;
          flex-grow: 1;
          flex-basis: 0; }
          @media (max-width: 719px) {
            [data-page="works"] .introContainer .whiteWrap .intro .description .texts .text_col {
              padding: 2rem 0 0 0;
              margin: 0; } }
          [data-page="works"] .introContainer .whiteWrap .intro .description .texts .text_col:first-child {
            margin-left: -1.5rem; }
            @media (max-width: 719px) {
              [data-page="works"] .introContainer .whiteWrap .intro .description .texts .text_col:first-child {
                padding: 2rem 0 0 0;
                margin: 0; } }
          [data-page="works"] .introContainer .whiteWrap .intro .description .texts .text_col:last-child {
            margin-right: -1.5rem; }
            @media (max-width: 719px) {
              [data-page="works"] .introContainer .whiteWrap .intro .description .texts .text_col:last-child {
                padding: 2rem 0 0 0;
                margin: 0; } }
          [data-page="works"] .introContainer .whiteWrap .intro .description .texts .text_col p {
            margin-bottom: 0rem;
            line-height: 1.5; }
            [data-page="works"] .introContainer .whiteWrap .intro .description .texts .text_col p:first-child strong,
            [data-page="works"] .introContainer .whiteWrap .intro .description .texts .text_col p:first-child b {
              display: inline-block;
              margin-bottom: 0.4rem; }

[data-page="works"] .work_title_block,
[data-page="works"] .work_text_block,
[data-page="works"] .work_image_block,
[data-page="works"] .work_double_image_block,
[data-page="works"] .work_video_block,
[data-page="works"] .work_gallery_block {
  margin-top: 9rem; }
  @media (max-width: 719px) {
    [data-page="works"] .work_title_block,
    [data-page="works"] .work_text_block,
    [data-page="works"] .work_image_block,
    [data-page="works"] .work_double_image_block,
    [data-page="works"] .work_video_block,
    [data-page="works"] .work_gallery_block {
      margin-top: 5rem; } }

[data-page="works"] .work_title_block h2 {
  margin: 0;
  text-align: center;
  font-size: 3.65rem; }

[data-page="works"] .work_text_block {
  width: 100%;
  padding: 0 2rem;
  max-width: 116rem;
  margin: 4.5rem auto 0; }
  [data-page="works"] .work_text_block div {
    column-gap: 3rem; }
    @media (max-width: 719px) {
      [data-page="works"] .work_text_block div {
        columns: 1 !important; } }
  [data-page="works"] .work_text_block p {
    margin-bottom: 0rem;
    line-height: 1.5; }
    [data-page="works"] .work_text_block p:first-child strong,
    [data-page="works"] .work_text_block p:first-child b {
      display: inline-block;
      margin-bottom: 0.4rem; }

[data-page="works"] .work_image_block .full,
[data-page="works"] .work_video_block .full,
[data-page="works"] .work_double_image_block .full {
  width: 100%;
  position: relative; }

[data-page="works"] .work_image_block .contained,
[data-page="works"] .work_video_block .contained,
[data-page="works"] .work_double_image_block .contained {
  width: 100%;
  max-width: 112rem;
  margin: 0 auto;
  position: relative; }

[data-page="works"] .work_image_block .only-mobile,
[data-page="works"] .work_video_block .only-mobile,
[data-page="works"] .work_double_image_block .only-mobile {
  display: none; }
  @media (max-width: 719px) {
    [data-page="works"] .work_image_block .only-mobile,
    [data-page="works"] .work_video_block .only-mobile,
    [data-page="works"] .work_double_image_block .only-mobile {
      display: block; } }

[data-page="works"] .work_image_block .only-desktop,
[data-page="works"] .work_video_block .only-desktop,
[data-page="works"] .work_double_image_block .only-desktop {
  display: block; }
  @media (max-width: 719px) {
    [data-page="works"] .work_image_block .only-desktop,
    [data-page="works"] .work_video_block .only-desktop,
    [data-page="works"] .work_double_image_block .only-desktop {
      display: none; } }

[data-page="works"] .work_image_block .play,
[data-page="works"] .work_video_block .play,
[data-page="works"] .work_double_image_block .play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
  z-index: 70;
  transition: filter 0.2s; }
  [data-page="works"] .work_image_block .play::after,
  [data-page="works"] .work_video_block .play::after,
  [data-page="works"] .work_double_image_block .play::after {
    content: url("../img/play.svg"); }
  [data-page="works"] .work_image_block .play:hover,
  [data-page="works"] .work_video_block .play:hover,
  [data-page="works"] .work_double_image_block .play:hover {
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.6)); }

[data-page="works"] .work_image_block .mute,
[data-page="works"] .work_video_block .mute,
[data-page="works"] .work_double_image_block .mute {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 70;
  cursor: pointer;
  display: none; }
  [data-page="works"] .work_image_block .mute::after,
  [data-page="works"] .work_video_block .mute::after,
  [data-page="works"] .work_double_image_block .mute::after {
    content: url("../img/muted.svg"); }
  [data-page="works"] .work_image_block .mute[data-unmuted]::after,
  [data-page="works"] .work_video_block .mute[data-unmuted]::after,
  [data-page="works"] .work_double_image_block .mute[data-unmuted]::after {
    content: url("../img/unmuted.svg"); }

[data-page="works"] .work_image_block [data-autoplay="autoplay"] .play,
[data-page="works"] .work_video_block [data-autoplay="autoplay"] .play,
[data-page="works"] .work_double_image_block [data-autoplay="autoplay"] .play {
  /* display: none; */ }

[data-page="works"] .work_image_block [data-autoplay="autoplay"] .mute,
[data-page="works"] .work_video_block [data-autoplay="autoplay"] .mute,
[data-page="works"] .work_double_image_block [data-autoplay="autoplay"] .mute {
  display: inline; }

[data-page="works"] .work_image_block .playing .play,
[data-page="works"] .work_video_block .playing .play,
[data-page="works"] .work_double_image_block .playing .play {
  display: none; }

[data-page="works"] .work_image_block img,
[data-page="works"] .work_image_block video,
[data-page="works"] .work_video_block img,
[data-page="works"] .work_video_block video,
[data-page="works"] .work_double_image_block img,
[data-page="works"] .work_double_image_block video {
  width: 100%; }

[data-page="works"] .work_double_image_block .double {
  font-size: 0;
  white-space: nowrap;
  overflow: hidden; }
  @media (max-width: 839px) {
    [data-page="works"] .work_double_image_block .double {
      white-space: normal; } }
  [data-page="works"] .work_double_image_block .double img {
    width: calc(50% - 0.5rem); }
    [data-page="works"] .work_double_image_block .double img:first-child {
      margin-right: 1rem; }
    @media (max-width: 839px) {
      [data-page="works"] .work_double_image_block .double img {
        width: 100%; }
        [data-page="works"] .work_double_image_block .double img:first-child {
          margin-right: 0;
          margin-bottom: 1rem; } }

[data-page="works"] .work_gallery_block {
  width: 100%;
  max-width: 112rem;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  [data-page="works"] .work_gallery_block .gallery_forward {
    right: 0;
    cursor: url("../img/pointer_forward.svg") 48 15, pointer; }
  [data-page="works"] .work_gallery_block .gallery_back {
    cursor: url("../img/pointer_back.svg") 0 15, pointer; }
  [data-page="works"] .work_gallery_block .gallery_back,
  [data-page="works"] .work_gallery_block .gallery_forward {
    position: absolute;
    width: 15%;
    top: 0;
    bottom: 0;
    z-index: 98; }
  [data-page="works"] .work_gallery_block .gallery_wrap {
    font-size: 0;
    overflow: hidden; }
    [data-page="works"] .work_gallery_block .gallery_wrap .gallery_container {
      width: 10000px;
      width: intrinsic;
      width: max-content; }
      [data-page="works"] .work_gallery_block .gallery_wrap .gallery_container .slide {
        margin-right: 3rem;
        display: inline-block; }
        [data-page="works"] .work_gallery_block .gallery_wrap .gallery_container .slide:last-child {
          margin-right: 0; }
        [data-page="works"] .work_gallery_block .gallery_wrap .gallery_container .slide img,
        [data-page="works"] .work_gallery_block .gallery_wrap .gallery_container .slide video {
          max-height: calc(100vh - 6.9rem - 15rem);
          max-width: calc(112rem - 15rem);
          vertical-align: middle; }
          @media (max-width: 1160px) {
            [data-page="works"] .work_gallery_block .gallery_wrap .gallery_container .slide img,
            [data-page="works"] .work_gallery_block .gallery_wrap .gallery_container .slide video {
              max-height: calc(100vh - 6.9rem - 8rem);
              max-width: 100vw; } }
  [data-page="works"] .work_gallery_block .gallery_data {
    font-size: 1.8rem;
    margin-top: 1.5rem; }
    @media (max-width: 1160px) {
      [data-page="works"] .work_gallery_block .gallery_data {
        padding-left: 3.5rem; } }

[data-page="works"] .bottom {
  width: calc(100% - 7rem);
  max-width: 112rem;
  padding: 1.4rem 0;
  margin: 9rem auto;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 459px) {
    [data-page="works"] .bottom {
      padding: 0; } }
  [data-page="works"] .bottom a {
    font-size: 1.2rem; }
    @media (max-width: 719px) {
      [data-page="works"] .bottom a {
        margin-top: 0.3rem;
        margin-bottom: 0.3rem; } }
    [data-page="works"] .bottom a.inactive {
      opacity: 0.5;
      pointer-events: none;
      text-decoration: none; }
  [data-page="works"] .bottom div {
    display: flex;
    justify-content: center;
    flex-direction: column; }
    [data-page="works"] .bottom div:nth-child(1) {
      text-align: left; }
      @media (max-width: 459px) {
        [data-page="works"] .bottom div:nth-child(1) a {
          font-size: 0;
          color: transparent;
          width: 2rem;
          padding: 1rem 0;
          display: inline-block;
          position: relative;
          top: -2px; }
          [data-page="works"] .bottom div:nth-child(1) a::before {
            content: "<";
            font-size: 2rem;
            color: black; } }
    [data-page="works"] .bottom div:nth-child(2), [data-page="works"] .bottom div:nth-child(3) {
      text-align: center; }
    [data-page="works"] .bottom div:nth-child(4) {
      text-align: right; }
      @media (max-width: 459px) {
        [data-page="works"] .bottom div:nth-child(4) a {
          font-size: 0;
          color: transparent;
          width: 2rem;
          padding: 1rem 0;
          display: inline-block;
          position: relative;
          top: -2px; }
          [data-page="works"] .bottom div:nth-child(4) a::before {
            content: ">";
            font-size: 2rem;
            color: black; } }

[data-page="works"][data-info-visible] .introContainer .intro .heading .infoTrigger::after {
  content: " –"; }

[data-page="works"][data-info-visible] .introContainer .intro .description {
  max-height: 1000px; }

[data-page="works"] .mosaic section:first-child {
  margin-top: 0 !important;
  background-color: white; }
  @media (max-width: 719px) {
    [data-page="works"] .mosaic section:first-child {
      margin-top: 5rem !important; } }

[data-page="about"][data-justscrolled] header {
  background-color: white;
  color: black; }
  [data-page="about"][data-justscrolled] header .menuTrigger span {
    background-color: #000; }

[data-page="about"] .carousel {
  height: 100vh; }

[data-page="about"] h1 {
  font-size: 4.1rem;
  margin: 0 0 2rem; }
  @media (max-width: 719px) {
    [data-page="about"] h1 {
      font-size: 3.9rem; } }

[data-page="about"] h2 {
  font-size: 4.1rem;
  margin-top: 0;
  margin-bottom: 1.8rem;
  padding-right: 6rem; }
  @media (max-width: 719px) {
    [data-page="about"] h2 {
      font-size: 3.9rem;
      padding-right: 0; } }

[data-page="about"] h3 {
  font-size: 1.9rem;
  display: inline-block;
  font-weight: bold; }
  [data-page="about"] h3.alignfix {
    padding-top: 0.2rem; }

[data-page="about"] p {
  font-size: 1.9rem; }
  [data-page="about"] p:last-child {
    margin-bottom: 1.9rem; }

[data-page="about"] .aboutWrap {
  padding: 7.6rem 2rem 8.2rem; }
  @media (max-width: 1023px) {
    [data-page="about"] .aboutWrap {
      padding-left: 9rem !important;
      padding-right: 9rem !important; }
      [data-page="about"] .aboutWrap .row {
        margin-left: 0;
        margin-right: 0;
        display: block; }
        [data-page="about"] .aboutWrap .row .col,
        [data-page="about"] .aboutWrap .row .col-1,
        [data-page="about"] .aboutWrap .row .col-2 {
          padding: 0; } }
  @media (max-width: 719px) {
    [data-page="about"] .aboutWrap {
      padding-left: 3.5rem !important;
      padding-right: 3.5rem !important;
      padding-top: 6rem;
      padding-bottom: 6rem; } }
  [data-page="about"] .aboutWrap ul {
    font-size: 1.9rem;
    margin: 0 0 4.9rem;
    padding: 0;
    list-style: none;
    display: block; }
    [data-page="about"] .aboutWrap ul li {
      border-bottom: 1px solid black;
      padding: 1.1rem 0;
      line-height: 1.2; }
      [data-page="about"] .aboutWrap ul li p {
        margin-bottom: 0 !important; }
      [data-page="about"] .aboutWrap ul li:last-child {
        border-bottom: none; }
  [data-page="about"] .aboutWrap.aboutIntro {
    background-color: #ff82b6; }
    @media (max-width: 719px) {
      [data-page="about"] .aboutWrap.aboutIntro {
        padding-top: 6rem;
        padding-bottom: 6rem; } }
    @media (max-width: 719px) {
      [data-page="about"] .aboutWrap.aboutIntro .col:nth-child(3) {
        margin-top: 4rem; } }
    [data-page="about"] .aboutWrap.aboutIntro ul {
      margin: 0 0 1.9rem -20px; }
      [data-page="about"] .aboutWrap.aboutIntro ul li {
        padding: 0 0 0 20px;
        line-height: 1.4;
        position: relative; }
        [data-page="about"] .aboutWrap.aboutIntro ul li::before {
          content: url("../img/li-black.svg");
          display: inline-block;
          margin-right: 10px;
          position: absolute;
          left: 0;
          top: 0; }
  [data-page="about"] .aboutWrap.aboutServices {
    background-color: #ffa700; }
  [data-page="about"] .aboutWrap.aboutClients {
    background-color: #ffdb00; }
  [data-page="about"] .aboutWrap.aboutRecognitions {
    background-color: #48d37a; }
    [data-page="about"] .aboutWrap.aboutRecognitions .recognition {
      font-size: 1.9rem;
      line-height: 1.3; }
      [data-page="about"] .aboutWrap.aboutRecognitions .recognition p {
        line-height: 1.3;
        margin-bottom: 0 !important; }
    [data-page="about"] .aboutWrap.aboutRecognitions .recognitionBlock {
      margin-bottom: 5rem; }
      @media (max-width: 1023px) {
        [data-page="about"] .aboutWrap.aboutRecognitions .recognitionBlock {
          margin-top: 2rem; } }
      [data-page="about"] .aboutWrap.aboutRecognitions .recognitionBlock h3 {
        margin-bottom: 4rem; }
      [data-page="about"] .aboutWrap.aboutRecognitions .recognitionBlock hr {
        border-style: none;
        border-top: 1px solid black;
        margin: 1.2rem 0; }
        [data-page="about"] .aboutWrap.aboutRecognitions .recognitionBlock hr:last-child {
          display: none; }
      [data-page="about"] .aboutWrap.aboutRecognitions .recognitionBlock ul {
        margin-bottom: 0; }
  [data-page="about"] .aboutWrap.aboutSocial {
    background-color: #1ca2ea; }
    [data-page="about"] .aboutWrap.aboutSocial ul {
      margin: 0 0 1.9rem -20px; }
      [data-page="about"] .aboutWrap.aboutSocial ul li {
        padding: 0 0 0 20px;
        line-height: 1.4;
        position: relative; }
        [data-page="about"] .aboutWrap.aboutSocial ul li::before {
          content: url("../img/li-black.svg");
          display: inline-block;
          margin-right: 10px;
          position: absolute;
          left: 0;
          top: 0; }
  [data-page="about"] .aboutWrap.aboutTeaching {
    padding-bottom: 15.3rem;
    margin-bottom: -7.4rem;
    background-color: #b4bfc3; }
    [data-page="about"] .aboutWrap.aboutTeaching .teachingList .singleTeaching {
      margin-top: 9rem; }
      [data-page="about"] .aboutWrap.aboutTeaching .teachingList .singleTeaching .singleTeachingText {
        margin-top: 4rem; }
        [data-page="about"] .aboutWrap.aboutTeaching .teachingList .singleTeaching .singleTeachingText h3 {
          margin-top: 0.3rem;
          text-transform: uppercase; }
        [data-page="about"] .aboutWrap.aboutTeaching .teachingList .singleTeaching .singleTeachingText .aux_texts {
          padding-right: 5rem;
          margin-bottom: 0; }
        [data-page="about"] .aboutWrap.aboutTeaching .teachingList .singleTeaching .singleTeachingText p {
          margin-bottom: 0; }
        [data-page="about"] .aboutWrap.aboutTeaching .teachingList .singleTeaching .singleTeachingText a[data-modal-trigger] {
          font-size: 1.9rem;
          font-weight: bold;
          margin-top: 2.2rem; }
          [data-page="about"] .aboutWrap.aboutTeaching .teachingList .singleTeaching .singleTeachingText a[data-modal-trigger]::before {
            content: url("../img/li-black.svg");
            display: inline-block;
            margin-right: 10px; }

[data-page="about"] .columns-2 {
  column-gap: 6rem;
  margin-top: -3rem; }
  [data-page="about"] .columns-2 h3 {
    margin-top: 4.9rem; }
  [data-page="about"] .columns-2 ul {
    margin-bottom: 0; }
  @media (max-width: 719px) {
    [data-page="about"] .columns-2 {
      columns: 1; } }
  [data-page="about"] .columns-2 > div.no-break {
    break-inside: avoid-column; }

@media (max-width: 1023px) {
  [data-page="about"] .container .row .col-2 {
    max-width: none;
    margin-top: 2rem; } }

[data-page="network"] {
  padding-top: 14rem;
  background-color: #ffa700;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between; }
  [data-page="network"][data-justscrolled] header {
    background-color: white; }
  [data-page="network"] header {
    background-color: transparent; }
  [data-page="network"] h1 {
    font-size: 5rem;
    margin: 0 0 2rem; }
    [data-page="network"] h1 + p {
      margin-bottom: 4rem; }
    @media (max-width: 719px) {
      [data-page="network"] h1 {
        font-size: 4.2rem; } }
  [data-page="network"] h2 {
    font-size: 4.1rem;
    margin-top: 0;
    margin-bottom: 1.8rem; }
    @media (max-width: 719px) {
      [data-page="network"] h2 {
        font-size: 3.9rem; } }
  [data-page="network"] h3 {
    font-size: 1.9rem;
    display: inline-block;
    border: 1px solid black;
    padding: 0.8rem;
    margin-top: 0;
    margin-bottom: 0.8rem; }
  [data-page="network"] p {
    font-size: 1.9rem; }
  [data-page="network"] .columns-2 {
    column-gap: 6rem; }
  [data-page="network"] h4 {
    margin-top: 5rem;
    font-size: 1.9rem;
    margin-bottom: 3rem; }
  [data-page="network"] .main_roles {
    margin-top: 1.3rem; }
  [data-page="network"] hr {
    border-style: none;
    border-top: 1px solid #000;
    margin: 1.4rem 0; }
  [data-page="network"] .collaborator_roles hr:last-child {
    display: none; }
  [data-page="network"] .main_roles p,
  [data-page="network"] .collaborator_roles p {
    font-size: 1.8rem; }
  [data-page="network"] footer {
    margin-top: 2rem; }
  @media (max-width: 839px) {
    [data-page="network"] .columns-2 {
      columns: 1; } }
  [data-page="network"] .container {
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
    @media (max-width: 1023px) {
      [data-page="network"] .container .row {
        display: block; } }
    @media (max-width: 1023px) {
      [data-page="network"] .container .row .col-2 {
        max-width: none;
        margin-top: 2rem; } }

[data-page="contacts"] {
  padding-top: 14rem;
  background-color: #c2b8ff;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between; }
  [data-page="contacts"][data-justscrolled] header {
    background-color: white; }
  [data-page="contacts"] header {
    background-color: transparent; }
  [data-page="contacts"] h1 {
    font-size: 5rem;
    margin: 0 0 2rem; }
    @media (max-width: 719px) {
      [data-page="contacts"] h1 {
        font-size: 4.2rem; } }
  [data-page="contacts"] h2 {
    font-size: 4.1rem;
    margin-top: 0;
    margin-bottom: 1.8rem; }
    @media (max-width: 719px) {
      [data-page="contacts"] h2 {
        font-size: 3.9rem; } }
  [data-page="contacts"] h3 {
    font-size: 1.8rem;
    display: inline-block;
    border: 1px solid black;
    padding: 0.8rem;
    margin-top: 0;
    margin-bottom: 0.8rem; }
  [data-page="contacts"] p {
    font-size: 1.8rem;
    line-height: 1.5; }
    [data-page="contacts"] p.privacy-disclaimer {
      font-size: 1.2rem;
      color: rgba(0, 0, 0, 0.6); }
  [data-page="contacts"] .wrap {
    margin-top: 0.5rem;
    margin-bottom: 2.6rem;
    padding-bottom: 2.5rem;
    border-bottom: 1px solid;
    width: fit-content; }
    [data-page="contacts"] .wrap:last-child {
      border-bottom: none;
      padding-bottom: 0; }
    [data-page="contacts"] .wrap p {
      margin-bottom: 0.1rem; }
  @media (max-width: 839px) {
    [data-page="contacts"] .left .wrap:last-child {
      padding-bottom: 2.5rem;
      border-bottom: 1px solid; } }
  [data-page="contacts"] .columns-2 {
    column-gap: 6rem; }
    @media (max-width: 1023px) {
      [data-page="contacts"] .columns-2 {
        columns: 1; } }
  @media (max-width: 839px) {
    [data-page="contacts"] .row {
      margin-left: 0;
      margin-right: 0;
      display: block; }
      [data-page="contacts"] .row .col,
      [data-page="contacts"] .row .col-1,
      [data-page="contacts"] .row .col-2 {
        padding: 0;
        width: 100%;
        max-width: 100%; } }
  [data-page="contacts"] h4 {
    margin-top: 1rem;
    font-size: 1.8rem;
    margin-bottom: 1rem; }
  [data-page="contacts"] hr {
    border-style: none;
    border-top: 1px solid #000;
    margin: 3.8rem 0; }
  [data-page="contacts"] .container {
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
    [data-page="contacts"] .container .socials {
      margin: 0;
      padding: 0;
      display: block;
      list-style: none; }
      [data-page="contacts"] .container .socials li {
        font-size: 1.8rem;
        display: inline-block;
        margin: 0 3.8rem 0 0; }
        @media (max-width: 719px) and (orientation: portrait) {
          [data-page="contacts"] .container .socials li {
            margin-right: 1.5rem; } }

@keyframes rotateWork {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
  [data-page="contacts"] .workTogether {
    position: relative;
    width: 14rem;
    height: 14rem;
    display: inline-block;
    background: transparent;
    border: none;
    cursor: pointer; }
    [data-page="contacts"] .workTogether::before {
      content: "";
      width: 14rem;
      height: 14rem;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url("../img/envelope.svg");
      background-size: contain; }
    [data-page="contacts"] .workTogether .rotating {
      width: 14rem;
      height: 14rem;
      position: absolute;
      top: 0;
      left: 0; }
    [data-page="contacts"] .workTogether:hover {
      transform: none; }
      [data-page="contacts"] .workTogether:hover .rotating {
        animation: rotateWork 7s infinite linear; }
  @media (max-width: 1023px) {
    [data-page="contacts"] .address .col:nth-child(2) {
      margin-top: 3rem; } }
  [data-page="contacts"] form {
    margin-top: 6.5rem;
    margin-bottom: 14rem; }
    [data-page="contacts"] form .has-danger input[type="text"],
    [data-page="contacts"] form .has-danger input[type="email"],
    [data-page="contacts"] form .has-danger textarea {
      border-color: red; }
    [data-page="contacts"] form .has-danger label,
    [data-page="contacts"] form .has-danger label p {
      color: red; }
    [data-page="contacts"] form label {
      display: block;
      font-size: 1.8rem; }
    [data-page="contacts"] form input[type="text"],
    [data-page="contacts"] form input[type="email"],
    [data-page="contacts"] form textarea {
      border: 1px solid #000;
      font-size: 1.8rem;
      padding: 1.2rem;
      display: block;
      width: 100%;
      margin: 1.2rem 0;
      background-color: transparent;
      appearance: none; }
      [data-page="contacts"] form input[type="text"]:focus,
      [data-page="contacts"] form input[type="email"]:focus,
      [data-page="contacts"] form textarea:focus {
        background-color: rgba(0, 0, 0, 0.05);
        outline: none; }
    [data-page="contacts"] form input[type="submit"] {
      display: inline-block;
      width: auto;
      padding-left: 3rem;
      padding-right: 3rem;
      background-color: black;
      color: #d5dcdc; }
    [data-page="contacts"] form .checkboxgroup {
      display: flex;
      margin-top: 3.5rem;
      margin-bottom: 3.5rem; }
      [data-page="contacts"] form .checkboxgroup .checkbox input {
        opacity: 0;
        height: 0.1px;
        width: 0.1px;
        position: absolute;
        pointer-events: none; }
      [data-page="contacts"] form .checkboxgroup .checkbox input + .box {
        width: 2.5rem;
        height: 2.5rem;
        border: 1px solid;
        display: inline-block;
        margin-right: 1rem; }
      [data-page="contacts"] form .checkboxgroup .checkbox input:checked + .box {
        background-color: black; }
      [data-page="contacts"] form .checkboxgroup .checkbox + label a {
        text-decoration: underline; }
    [data-page="contacts"] form textarea {
      height: 33.5rem; }

.modalgallery {
  background-color: white;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  transition: 0.5s opacity; }
  .modalgallery[data-modal-open] {
    opacity: 1;
    pointer-events: initial; }
  .modalgallery .gallerycontainer .gallery_forward {
    right: 0;
    cursor: url("../img/pointer_forward.svg") 47 14, pointer; }
  .modalgallery .gallerycontainer .gallery_back {
    cursor: url("../img/pointer_back.svg") 0 14, pointer; }
  .modalgallery .gallerycontainer .gallery_back,
  .modalgallery .gallerycontainer .gallery_forward {
    position: absolute;
    width: 15%;
    top: 0;
    bottom: 0;
    z-index: 98; }
  .modalgallery .gallerycontainer [data-modal-close] {
    position: absolute;
    display: inline-block;
    top: 2rem;
    right: 3.5rem;
    height: 2.3rem;
    width: 2.3rem;
    z-index: 99;
    background-image: url("../img/close.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }
  .modalgallery .gallerycontainer .gallery_wrap {
    font-size: 0;
    overflow: hidden; }
    .modalgallery .gallerycontainer .gallery_wrap .gallery_container {
      width: 10000px;
      width: intrinsic;
      width: max-content; }
      .modalgallery .gallerycontainer .gallery_wrap .gallery_container .slide {
        height: 100vh;
        width: 100vw;
        display: inline-block; }
        .modalgallery .gallerycontainer .gallery_wrap .gallery_container .slide > div,
        .modalgallery .gallerycontainer .gallery_wrap .gallery_container .slide video {
          height: 100%;
          width: 100%; }
        .modalgallery .gallerycontainer .gallery_wrap .gallery_container .slide > div {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain; }
