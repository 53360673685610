html,
body {
  font-size: 10px;
  @media (max-width: $bp_xs) {
    font-size: 9.5px;
  }

  cursor: url("../img/pointer_normal.svg") 7 7, auto;
}

html[data-responsivefont-override],
html[data-responsivefont-override] body {
  font-size: 10px;
  @media (max-width: $bp_xs) {
    font-size: 10px;
  }
}

body {
  font-family: "CadizWeb";
  font-weight: 400;
  font-style: normal;

  text-rendering: optimizeLegibility;
  /* emphasizes in legibility over rendering speed */
  -webkit-font-smoothing: antialiased;
  /* apply font anti-aliasing */
  -moz-osx-font-smoothing: grayscale;
  /* optimize font rendering */

  -moz-font-feature-settings: "kern" 1;
  /* turn on kerning, highly recomened */
  -ms-font-feature-settings: "kern" 1;
  /* turn on kerning, highly recomened */
  -o-font-feature-settings: "kern" 1;
  /* turn on kerning, highly recomened */
  -webkit-font-feature-settings: "kern" 1;
  /* turn on kerning, highly recomened */
  font-feature-settings: "kern" 1;
  /* turn on kerning, highly recomened */
  font-kerning: normal;
  /* turn on kerning, highly recomened */

  font-feature-settings: "liga" on;
  /* ligatures: on or off */
  font-feature-settings: "dlig" off;
  /* discretionary-ligatures: on or off */
  font-feature-settings: "tnum" off;
  /* tabular figures: on or off */
  font-feature-settings: "onum" off;
  /* old-style-figures: on or off */
  font-feature-settings: "ss01" off;
  /* alternate glyphs (stylistic Set): on or off */

  scroll-behavior: smooth;
}

a,
a:link,
a:focus,
a:hover,
a:visited {
  color: inherit;
  transition: transform 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: url("../img/pointer_pointer.svg") 7 7, pointer;
}

a:hover {
  text-decoration: underline;
  /*  transform: translateY(-2px); */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

h1 {
  font-size: 3.7rem;
  display: block;
}

p {
  font-size: 1.4rem;
  margin: 0 0 1.9rem 0;
  line-height: 1.4;

  &:last-child {
    margin-bottom: 0;
  }
}

[data-copy] textarea {
  opacity: 0;
  height: 0;
  width: 0;
  font-size: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  pointer-events: none;
}

.container {
  max-width: 1130px;
  margin: 0 auto;
  width: 100%;

  .row {
    margin-left: -3rem;
    margin-right: -3rem;
    display: flex;

    .col,
    .col-1,
    .col-2 {
      padding-left: 3rem;
      padding-right: 3rem;
      flex-grow: 1;
      flex-basis: 0;
    }

    .col-2 {
      flex: 0 0 66.666%;
      max-width: 66.666%;
    }
  }
}

.columns-2 {
  columns: 2;
}

.nowrap {
  white-space: nowrap;
}

.grecaptcha-badge {
  display: none !important;
}

.text-help {
  display: none;
}

.form-error-message {
  color: red;
  display: none;
}

.form-success-message {
  display: none;
}

.form-submit-button {
  &[data-sent] {
    display: none !important;
  }
}

.form-error-message,
.form-success-message {
  &[data-sent] {
    display: block;
  }
}
