body {
  padding-top: 6.9rem;
}

header {
  transform: translateZ(0);
  height: 6.9rem;
  padding: 0 3.5rem 0.2rem 3.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  transition: color 0.5s, background-color 0.5s, top 0.5s;

  background-color: white;

  .logo {
    transform: translateZ(0);
    font-size: 1.6rem;

    &:link,
    &:active,
    &:visited,
    &:hover,
    &:focus {
      text-decoration: none;
      color: inherit;
    }
  }

  .menuTrigger {
    transform: translateZ(0);
    width: 3rem;
    line-height: 0;
    font-size: 0;

    span {
      transform: translateZ(0);
      display: block;
      background-color: black;
      width: 3rem;
      height: 1px;
      margin-left: auto;
      margin-right: auto;
      transition: background-color 0.5s, transform 0.3s, opacity 0.1s;

      &:first-child {
        margin-bottom: 8px;
      }

      &:last-child {
        margin-top: 8px;
      }

      &:nth-child(3) {
        margin-top: -1px;
      }
    }
  }
}

body[data-scrolled][data-going="down"] {
  @media (max-width: $bp_xs) {
    header {
      top: -8.7rem;
    }
  }
}

body[data-page="home"],
body[data-page="about"] {
  padding-top: 0;

  header {
    color: white;
    background-color: transparent;

    .menuTrigger span {
      background-color: white;
    }
  }

  &[data-scrolled] {
    header {
      color: black;
      background-color: white;

      .menuTrigger span {
        background-color: black;
      }
    }
  }
}

body[data-page="about"] {
  header {
    color: black;

    .menuTrigger span {
      background-color: black;
    }
  }
}

footer {
  font-size: 1.2rem;
  padding: 3rem 3.5rem;
  display: flex;
  justify-content: space-between;

  & > div {
    &:nth-child(2) {
      text-align: center;
      flex-grow: 1;

      @media (max-width: $bp_sm) {
        text-align: right;
      }
    }

    &:nth-child(3) {
      text-align: right;
    }
  }

  .socials {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0;

    @media (max-width: $bp_sm) {
      display: none;

      &.privacy {
        display: inline-block;
      }
    }

    li {
      display: inline-block;
      margin: 0 0.7rem;
    }
  }

  .no-mobile {
    @media (max-width: $bp_sm) {
      display: none;
    }
  }

  .copyright span {
    position: relative;
    top: 0.1rem;
  }
}

@keyframes rainbow {
  0% {
    background-color: #ff82b6;
  }

  20% {
    background-color: #3ecf7a;
  }

  40% {
    background-color: #1ca2ea;
  }

  60% {
    background-color: #ffdb00;
  }

  80% {
    background-color: #b4bfc3;
  }

  100% {
    background-color: #ff82b6;
  }
}

@keyframes rainbowtext {
  0% {
    color: #ff82b6;
  }

  20% {
    color: #3ecf7a;
  }

  40% {
    color: #1ca2ea;
  }

  60% {
    color: #ffdb00;
  }

  80% {
    color: #b4bfc3;
  }

  100% {
    color: #ff82b6;
  }
}

nav {
  opacity: 0;
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: 25s infinite rainbow;
  z-index: 89;
  display: flex;
  transition: opacity 0.5s;

  .pages {
    margin: 7rem 0 0 0;
    padding: 0;
    display: block;
    list-style: none;
    height: 37rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media (max-width: $bp_sm) and (orientation: portrait) {
      justify-content: flex-start;
      height: auto;
    }

    @media (max-height: $bp_xs) and (orientation: landscape) {
      justify-content: center;
      padding-bottom: 5rem;
    }

    li {
      font-size: 8rem;
      transition: opacity 0.6s;
      opacity: 0;

      @media (max-width: $bp_lgg) {
        font-size: 7.5rem;
      }

      @media (max-width: $bp_lg) {
        font-size: 7.5rem;
      }

      @media (max-width: $bp_sm) and (orientation: portrait) {
        font-size: 3.7rem;
        margin-bottom: 0.5rem;
      }

      @media (max-height: $bp_xs) and (orientation: landscape) {
        font-size: 4rem;
      }

      a {
        text-decoration: none;
      }
    }
  }

  .socials {
    margin: 7.5rem 0 0 0;
    padding: 0;
    display: block;
    list-style: none;
    height: 2.1rem;
    opacity: 0;
    transition: opacity 0.6s;

    @media (max-width: $bp_sm) and (orientation: portrait) {
      width: calc(100vw - 5rem);
    }

    @media (max-height: $bp_xs) and (orientation: landscape) {
      display: none;
    }

    li {
      font-size: 1.8rem;
      display: inline-block;
      margin: 0 3.8rem 0 0;

      @media (max-width: $bp_sm) and (orientation: portrait) {
        font-size: 1.5rem;
        margin-right: 1.5rem;
      }
    }
  }

  .sub {
    margin: 0 0 9.6rem 0;
    padding: 0 0 1rem 0;
    display: block;
    list-style: none;
    height: 37rem;
    opacity: 0;
    transition: opacity 0.6s;

    @media (max-width: $bp_sm) and (orientation: portrait) {
      justify-content: flex-start;
      padding-top: 9.6rem;
      margin-bottom: 1rem;
      height: auto;
    }

    li {
      font-size: 1.8rem;
      display: block;
      padding-top: 1.6rem;
      margin: 0 3.8rem 0 0;

      @media (max-width: $bp_sm) and (orientation: portrait) {
        font-size: 1.5rem;
        padding-top: 1.4rem;
      }

      a {
        text-decoration: none;
      }
    }
  }

  .main {
    margin-left: 33.333%;
    width: 33.333%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 6rem;

    .lang {
      height: 7rem;
      opacity: 0;
      transition: opacity 0.6s;
      animation: 25s infinite rainbowtext;
      display: none;

      @media (max-width: $bp_sm) and (orientation: portrait) {
        display: inline-block;
        margin-top: 1rem;
      }

      a {
        font-size: 1.6rem;
        height: 3.4rem;
        width: 3.4rem;
        border: 1px solid #000;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: black;
        text-decoration: none;

        &:hover {
          background-color: black;
          color: inherit;
        }
      }
    }

    @media (max-width: $bp_lg) {
      margin-left: 20%;
      width: 53.333%;
    }

    @media (max-width: $bp_sm) and (orientation: portrait) {
      margin-left: 3.5rem;
      width: 50%;
      padding-top: 3rem;
      justify-content: space-between;
    }

    @media (max-height: $bp_xs) and (orientation: landscape) {
      width: 100%;
      margin: 0;
      text-align: center;
      padding: 0;
    }
  }

  .side {
    width: 33.333%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 6rem;

    @media (max-width: $bp_sm) and (orientation: portrait) {
      justify-content: flex-start;
      width: 50%;
    }

    @media (max-height: $bp_xs) and (orientation: landscape) {
      display: none;
    }

    .lang {
      height: 7rem;
      opacity: 0;
      transition: opacity 0.6s;
      animation: 25s infinite rainbowtext;

      @media (max-width: $bp_sm) and (orientation: portrait) {
        display: none;
      }

      a {
        font-size: 1.6rem;
        height: 3.4rem;
        width: 3.4rem;
        border: 1px solid #000;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: black;
        text-decoration: none;

        &:hover {
          background-color: black;
          color: inherit;
        }
      }
    }
  }

  .sub {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    a:not(.langlink)::after {
      transition: right 0.3s;
    }
  }

  a:not(.langlink) {
    display: inline-block;
    position: relative;
    text-decoration: none;

    &::after {
      content: "";
      display: inline;
      position: absolute;
      left: 0;
      bottom: 0.03em;
      right: 500px;
      height: 0.06em;
      background-color: black;
      transition: right 0.4s;
    }

    &:hover {
      transform: none;

      &::after {
        right: 0;
      }
    }
  }
}

body[data-nav-open] {
  overflow: hidden;

  header {
    color: black !important;
    background-color: transparent !important;

    .menuTrigger span {
      background-color: black;

      &:first-child,
      &:last-child {
        opacity: 0;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }

  nav {
    opacity: 1;
    pointer-events: initial;

    .pages li {
      opacity: 1;

      &:nth-child(1) {
        transition-delay: 0.2s;
      }

      &:nth-child(2) {
        transition-delay: 0.4s;
      }

      &:nth-child(3) {
        transition-delay: 0.6s;
      }

      &:nth-child(4) {
        transition-delay: 0.8s;
      }
    }

    .socials,
    .lang,
    .sub {
      opacity: 1;
      transition-delay: 1.2s;
    }
  }
}

@keyframes pulse {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.3;
  }
}

.loadingSplash {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: white;
  z-index: 98;
  transition: 0.5s opacity;
  pointer-events: none;

  &::after {
    content: "";
    display: inline-block;
    width: 2.2rem;
    height: 2.2rem;
    background-color: black;
    border-radius: 50%;
    animation-duration: 0.8s;
    animation-name: pulse;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
}

[data-loading] .loadingSplash {
  opacity: 1;
}
