[data-page="network"] {
  padding-top: 14rem;
  background-color: #ffa700;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;

  &[data-justscrolled] {
    header {
      background-color: white;
    }
  }

  header {
    background-color: transparent;
  }

  h1 {
    font-size: 5rem;
    margin: 0 0 2rem;

    & + p {
      margin-bottom: 4rem;
    }

    @media (max-width: $bp_sm) {
      font-size: 4.2rem;
    }
  }

  h2 {
    font-size: 4.1rem;
    margin-top: 0;
    margin-bottom: 1.8rem;

    @media (max-width: $bp_sm) {
      font-size: 3.9rem;
    }
  }

  h3 {
    font-size: 1.9rem;
    display: inline-block;
    border: 1px solid black;
    padding: 0.8rem;
    margin-top: 0;
    margin-bottom: 0.8rem;
  }

  p {
    font-size: 1.9rem;
  }

  .columns-2 {
    column-gap: 6rem;
  }

  h4 {
    margin-top: 5rem;
    font-size: 1.9rem;
    margin-bottom: 3rem;
  }

  .main_roles {
    margin-top: 1.3rem;
  }

  hr {
    border-style: none;
    border-top: 1px solid #000;
    margin: 1.4rem 0;
  }

  .collaborator_roles {
    hr:last-child {
      display: none;
    }
  }

  .main_roles,
  .collaborator_roles {
    p {
      font-size: 1.8rem;
    }
  }

  footer {
    margin-top: 2rem;
  }

  .columns-2 {
    @media (max-width: $bp_md) {
      columns: 1;
    }
  }

  .container {
    padding-left: 3.5rem;
    padding-right: 3.5rem;

    .row {
      @media (max-width: $bp_lg) {
        display: block;
      }

      .col-2 {
        @media (max-width: $bp_lg) {
          max-width: none;
          margin-top: 2rem;
        }
      }
    }
  }
}
