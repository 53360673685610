[data-page="contacts"] {
  padding-top: 14rem;
  background-color: #c2b8ff;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;

  &[data-justscrolled] {
    header {
      background-color: white;
    }
  }

  header {
    background-color: transparent;
  }

  h1 {
    font-size: 5rem;
    margin: 0 0 2rem;

    @media (max-width: $bp_sm) {
      font-size: 4.2rem;
    }
  }

  h2 {
    font-size: 4.1rem;
    margin-top: 0;
    margin-bottom: 1.8rem;

    @media (max-width: $bp_sm) {
      font-size: 3.9rem;
    }
  }

  h3 {
    font-size: 1.8rem;
    display: inline-block;
    border: 1px solid black;
    padding: 0.8rem;
    margin-top: 0;
    margin-bottom: 0.8rem;
  }

  p {
    font-size: 1.8rem;
    line-height: 1.5;

    &.privacy-disclaimer {
      font-size: 1.2rem;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .wrap {
    margin-top: 0.5rem;
    margin-bottom: 2.6rem;
    padding-bottom: 2.5rem;
    border-bottom: 1px solid;
    width: fit-content;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    p {
      margin-bottom: 0.1rem;
    }
  }

  @media (max-width: $bp_md) {
    .left {
      .wrap:last-child {
        padding-bottom: 2.5rem;
        border-bottom: 1px solid;
      }
    }
  }

  .columns-2 {
    column-gap: 6rem;

    @media (max-width: $bp_lg) {
      columns: 1;
    }
  }

  @media (max-width: $bp_md) {
    .row {
      margin-left: 0;
      margin-right: 0;
      display: block;

      .col,
      .col-1,
      .col-2 {
        padding: 0;
        width: 100%;
        max-width: 100%;
      }
    }
  }

  h4 {
    margin-top: 1rem;
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  hr {
    border-style: none;
    border-top: 1px solid #000;
    margin: 3.8rem 0;
  }

  .container {
    padding-left: 3.5rem;
    padding-right: 3.5rem;

    .socials {
      margin: 0;
      padding: 0;
      display: block;
      list-style: none;

      li {
        font-size: 1.8rem;
        display: inline-block;
        margin: 0 3.8rem 0 0;

        @media (max-width: $bp_sm) and (orientation: portrait) {
          margin-right: 1.5rem;
        }
      }
    }
  }

  @keyframes rotateWork {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .workTogether {
    position: relative;
    width: 14rem;
    height: 14rem;
    display: inline-block;
    background: transparent;
    border: none;
    cursor: pointer;

    &::before {
      content: "";
      width: 14rem;
      height: 14rem;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url("../img/envelope.svg");
      background-size: contain;
    }

    .rotating {
      width: 14rem;
      height: 14rem;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:hover {
      transform: none;

      .rotating {
        animation: rotateWork 7s infinite linear;
      }
    }
  }

  .address {
    .col:nth-child(2) {
      @media (max-width: $bp_lg) {
        margin-top: 3rem;
      }
    }
  }

  form {
    margin-top: 6.5rem;
    margin-bottom: 14rem;

    .has-danger {
      input[type="text"],
      input[type="email"],
      textarea {
        border-color: red;
      }

      label,
      label p {
        color: red;
      }
    }

    label {
      display: block;
      font-size: 1.8rem;
    }

    input[type="text"],
    input[type="email"],
    textarea {
      border: 1px solid #000;
      font-size: 1.8rem;
      padding: 1.2rem;
      display: block;
      width: 100%;
      margin: 1.2rem 0;
      background-color: transparent;
      appearance: none;

      &:focus {
        background-color: rgba(0, 0, 0, 0.05);
        outline: none;
      }
    }

    input[type="submit"] {
      display: inline-block;
      width: auto;
      padding-left: 3rem;
      padding-right: 3rem;
      background-color: black;
      color: #d5dcdc;
    }

    .checkboxgroup {
      display: flex;
      margin-top: 3.5rem;
      margin-bottom: 3.5rem;

      .checkbox {
        input {
          opacity: 0;
          height: 0.1px;
          width: 0.1px;
          position: absolute;
          pointer-events: none;
        }

        input + .box {
          width: 2.5rem;
          height: 2.5rem;
          border: 1px solid;
          display: inline-block;
          margin-right: 1rem;
        }

        input:checked + .box {
          background-color: black;
        }
      }

      .checkbox + label {
        a {
          text-decoration: underline;
        }
      }
    }

    textarea {
      height: 33.5rem;
    }
  }
}
